// Externals
import React from 'react';

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Assets
import AssetImage from '../../assets/images/CSDF-436-min.jpg';

// styles
import './danceBattle.styles.scss';

const DanceBattle = () => {

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1   
    };

    return (
        <Slider {...settings} style={{ maxWidth: '100%', overflow: 'hidden' }}>  
            <div>
                <div className='DanceBattle'>
                    <div className='DanceBattle-image'>
                        <img src={AssetImage} alt="asset-image" />
                    </div>
                    <div className='DanceBattle-text'>
                        <h3 className='title'>DANCE BATTL<span className='yellow-top'>ES</span></h3>
                        <h5 className='date'>Saturday the 9th of April</h5>
                        <p className='paragraph'>Dance Battles form a huge part of Hip Hop dance culture. Come and see some of the best freestyle dancers in Australia battle it out at 12pm in Garema Place in a 3v3 All Style Battle.</p>
                        <p className='paragraph'>Better yet, form a team and register to enter the battle.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className='DanceBattle'>
                    <div className='DanceBattle-image'>
                        <img src={AssetImage} alt="asset-image" />
                    </div>
                    <div className='DanceBattle-text'>
                        <h3 className='title'>DANCE BATTL<span className='yellow-top'>ES</span></h3>
                        <h5 className='date'>Saturday the 9th of April</h5>
                        <p className='paragraph'>Dance Battles form a huge part of Hip Hop dance culture. Come and see some of the best freestyle dancers in Australia battle it out at 12pm in Garema Place in a 3v3 All Style Battle.</p>
                        <p className='paragraph'>Better yet, form a team and register to enter the battle.</p>
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export default DanceBattle;