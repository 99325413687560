// Externals
import React, {useState} from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// react-icons
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

// components
import Header from '../header/header.component';
import MyButton from '../myButton/myButton.component';

// styles 
import './heroImageCarousel.styles.scss';

const HeroImageCarousel = () => {

    const [sliderRef, setSliderRef] = useState(null)

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <>
        
        <Header />
        <div className='hero-image-carousel'>
            
            <button onClick={sliderRef?.slickPrev} className='arrow left'>
                <BsArrowLeft />
            </button>
            <button onClick={sliderRef?.slickNext} className='arrow right'>
                <BsArrowRight />
            </button>
          
            <Slider ref={setSliderRef} {...settings} style={{ maxWidth: '100%', overflow: 'hidden' }}>  
                <div>
                    <div className='container'>
                        <div>
                            <h3>
                                CANBERRA <br />
                                STREET DANCE <br />
                                FESTIVAL <br />
                            </h3>
                            <p>Celebrating the elements of Hip-Hop culture with peace, love, unity and fun!</p>
                            <MyButton />
                        </div>
                    </div>
                </div>
                
                <div>
                    <div className='container'>
                        <div>
                            <h3>
                                CANBERRA <br />
                                STREET DANCE <br />
                                FESTIVAL <br />
                            </h3>
                            <p>Celebrating the elements of Hip-Hop culture with peace, love, unity and fun!</p>
                            <MyButton />
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </>;
}

export default HeroImageCarousel;