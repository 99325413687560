// Externals
import React from 'react';

import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

// styles
import './footer.styles.scss';

const Footer = () => {

    return (
        <footer className='footer'>
            <ul>
                <li>
                    <a href="/">
                        HOME
                    </a>
                </li>
                <li>
                    <a href="#">
                        ABOUT
                    </a>
                </li>
                <li>
                    <a href="#">
                        LINE UPS
                    </a>
                </li>
                <li>
                    <a href="/packages">
                        PACKAGES
                    </a>
                </li>
                <li>
                    <a href="/stalls">
                        STALLS
                    </a>
                </li>
                <li>
                    <a href="#">
                        OFFERS
                    </a>
                </li>
                <li>
                    <a href="/volunteer">
                        VOLUNTEER
                    </a>
                </li>
                <li>
                    <a href="/contact-us">
                        CONTACT
                    </a>
                </li>
            </ul>
            <div className='icon-wrapper'>
                <div className='icon'>
                    <a href="#">
                        <FaFacebookF />
                    </a>
                </div>
                <div className='icon'>
                    <a href="#">
                        <FaInstagram />
                    </a>
                </div>
                <div className='icon'>
                    <a href="#">
                        <FaYoutube />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;