// Externals
import React from 'react';

// react-icon
import { FaTimes } from "react-icons/fa";

// styles
import './sideMenu.styles.scss';

const SideMenu = ({ show, onClickHandler }) => {

    return (
        <div className={`sideMenu ${ show ? 'block' : 'none' }`}>
            <ul>
                <li>
                    <a href="/">HOME</a>
                </li>
                <li>
                    <a href="#">ABOUT</a>
                </li>
                <li>
                    <a href="#">LINE UPS</a>
                </li>
                <li>
                    <a href="/packages">PACKAGES</a>
                </li>
                <li>
                    <a href="/stalls">STALLS</a>
                </li>
                <li>
                    <a href="#">OFFERS</a>
                </li>
                <li>
                    <a href="/volunteer">VOLUNTEER</a>
                </li>
                <li>
                    <a>CONTACT</a>
                </li>
            </ul>
            <div className='iconTimes' onClick={onClickHandler}>
                <FaTimes />
            </div>
        </div>
    );
}

export default SideMenu;