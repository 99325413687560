
// react-router-dom
import {
  Routes,
  Route
} from "react-router-dom";

// pages
import Homepage from "./pages/homepage/homepage.page";
import StallsPage from "./pages/stalls/stalls.page";
import VolunteerPage from "./pages/volunteer/volunteer.page";
import ContactUsPage from "./pages/contactUs/contactUs.page";
import PackagePage from "./pages/package/package.page";

// styles
import './App.scss';

function App() {
  return (
    <Routes>
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/packages" element={<PackagePage />} />
      <Route path="/volunteer" element={<VolunteerPage />} />
      <Route path="/stalls" element={<StallsPage />} />
      <Route path="/" element={<Homepage />} />
    </Routes>
  );
}

export default App;
