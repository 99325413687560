// Externals
import React from 'react';

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Assets
import AssetImage from '../../assets/images/CSDF-42-min.jpg';

// Styles
import './theGetDown.styles.scss';

const TheGetDown = () => {

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1   
    };
    
    return (
        <div style={{ backgroundColor: '#0f151b' }}>
            <Slider {...settings} style={{ maxWidth: '100%', overflow: 'hidden' }}>  
                <div>
                    <div className='TheGetDown'>
                        <div className='TheGetDown-text'>
                            <h3 className='title'><span className='yellow-top'>TH</span>E GET DOWN</h3>
                            <h5 className='date'>Saturday the 9th of April</h5>
                            <p className='paragraph'>After a long day at the festival, cut loose on the dance floor at Canberra's only Hip Hop and Street Dance party, "The Get Down". Enjoy beats spun by DJs an turntablists designed to help you get down on the dance floor. Get ready for a night of Hip Hop, Rnb, Disco, Funk, Afro, and House. Zaab will be serving up the specialty cocktails and snacks at discount prices.</p>
                        </div>
                        <div className='TheGetDown-img'>
                            <img src={AssetImage} alt="Asset Image" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='TheGetDown'>
                        <div className='TheGetDown-text'>
                            <h3 className='title'><span className='yellow-top'>TH</span>E GET DOWN</h3>
                            <h5 className='date'>Saturday the 9th of April</h5>
                            <p className='paragraph'>After a long day at the festival, cut loose on the dance floor at Canberra's only Hip Hop and Street Dance party, "The Get Down". Enjoy beats spun by DJs an turntablists designed to help you get down on the dance floor. Get ready for a night of Hip Hop, Rnb, Disco, Funk, Afro, and House. Zaab will be serving up the specialty cocktails and snacks at discount prices.</p>
                        </div>
                        <div className='TheGetDown-img'>
                            <img src={AssetImage} alt="Asset Image" />
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default TheGetDown;