// Externals
import React, { useState } from 'react';

// react-icon
import { GiHamburgerMenu } from "react-icons/gi";

// components
import SideMenu from '../sideMenu/sideMenu.component';

// Assets
import Logo from '../../assets/images/sdf-logo.png';

// styles
import './header.styles.scss';

const Header = () => {

    const [sideMenuShow, setSideMenuShow] = useState(false);
    
    const onClickHandler = () => {
        setSideMenuShow(state => !state);
    }

    return (
        <div className='header'>
            <div>
                <a href="/">
                    <img src={Logo} alt='logo' className='logo-img' />
                </a>
            </div>
            <div>
                <ul className='header-ul'>
                    <li className='header-li'>
                        <a href="/" className='active'>
                            HOME
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="#">
                            ABOUT
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="#">
                            LINE UPS
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="/packages">
                            PACKAGES
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="/stalls">
                            STALLS
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="#">
                            OFFERS
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="/volunteer">
                            VOLUNTEER
                        </a>
                    </li>
                    <li className='header-li'>
                        <a href="/contact-us">
                            CONTACT
                        </a>
                    </li>
                    <li id="hamburger" className='header-li' onClick={onClickHandler}>
                        <a href="#">
                            <GiHamburgerMenu />
                        </a>
                    </li>
                </ul>
            </div>
            <SideMenu show={sideMenuShow} onClickHandler={onClickHandler}/>
        </div>
    );
}

export default Header;