// Externals
import React from 'react';

// Assets
import AssetImage from '../../assets/images/CSDF-298-min.jpg';

// Styles
import './theFestival.styles.scss';

const TheFestival = () => {

    return (
        <div className='TheFestival'>
            <div className='text'>
                <h3 className='title'><span className='yellow-top'>TH</span>E FESTIVAL</h3>
                <h5 className='date'>Saturday the 9th of April 2022</h5>
                <p className='paragraph'>Head to Garema Place for a day of performances, dance battles, street fashion stalls, street art installations and more! The festival will be pumping from 10am - 6pm and is FREE for people of all ages to attend. Take advantage of some great festival deals from surrounding stores and soak up all that Canberra has to offer in the Hip Hop scene.</p>
            </div>
            <div className='image'>
                <img src={AssetImage} alt="asset-image" />
            </div>
        </div>
    );
}

export default TheFestival;
