// Externals
import React from 'react';

// react-slick
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// components
import Header from '../../components/header/header.component';
import MyTextInput from '../../components/myTextInput/myTextInput.component';
import MyTextArea from '../../components/myTextArea/myTextArea.component';
import MyButton from '../../components/myButton/myButton.component';
import Footer from '../../components/footer/footer.component';

// Assets
import AssetImage from '../../assets/images/CSDF-599-min.jpg';

// styles
import './stalls.styles.scss';

const StallsPage = () => {

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    
    return (
        <div>
            <Header />
            <Slider {...settings} >  
                <div>
                    <div className='image-carousel' style={{ backgroundImage: `url(${AssetImage})` }}>
                        <div className='wrapper'>
                            <h3>
                                STALL<br />
                                APPLICATIONS
                            </h3>
                        </div>
                    </div>
                </div>
            </Slider>
            <div style={{ backgroundColor: '#0f151b' }}>
                <div className='body'>
                    <h3>Stall Holder Registration</h3>
                    <p style={{ color: '#fff' }}>
                        We are seeking to collaborate with business and organisations
                        <br />
                        that shares our passion for street culture, art and building a vibrant local community
                    </p>
                    <form>
                        <MyTextInput label='name' name="name" />
                        <MyTextInput label='stall name' name="stall-name" />
                        <MyTextInput label='email' name="email" />
                        <MyTextInput label='phone number' name="phone-number" />
                        <MyTextArea label="Why would you like to be involved in Canberra SDF? " />
                        <MyTextArea label="Do you have any special requirements to run your stall ?" />
                        <MyTextArea label="What is your COVID-19 safety plan to protect festival goers and your employees?" />
                        <MyTextArea label="Public Liability Insurance policy details (including policy expiration date) You must have your own Public Liability Insurance to participate in the festival" />
                        <MyButton />
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default StallsPage;