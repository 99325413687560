// Externals
import React from 'react';

// styles
import './myButton.styles.scss';

const MyButton = () => {

    return (
        <div className='myButton'>
            <span className='btn-text'>Read More</span>
        </div>
    );
}

export default MyButton;