// Externals
import React, { useState, useCallback } from 'react';

// Components
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';

// styles
import './package.styles.scss';

const PackagePage = () => {

    const [active, setActive] = useState(1);

    const onClickHandler = useCallback((number) => {
        setActive(number);
    }, []);

    return (
        <div>
            <Header />
                <div style={{ backgroundColor: '#0f151b' }}>
                    <div className='packages'>
                        <div className={`wrapper ${active === 1 ? 'active' : ''}`} onClick={() => onClickHandler(1)}>
                            <h3>
                                Notorious B.I.G Package <br />
                                Early Bird $72<br />
                                Save 20%
                            </h3>
                            <h3>
                                (Individual = $90)
                            </h3>
                            <p>
                                Battle Registration <br />
                                Full Day of Workshops <br />
                                (select 3 sessions at check out)
                            </p>
                            <p>
                                The Get Down <br />
                                Merch Pack (T-shirt and socks) $40 <br />
                                Save 20%
                            </p>
                        </div>

                        <div className={`wrapper ${active === 2 ? 'active' : ''}`} onClick={() => onClickHandler(2)}>
                            <h3>
                                Grandmaster Flash Package <br />
                                $63<br />
                                Save 15%
                            </h3>
                            <h3>
                                (Individual = $75)
                            </h3>
                            <p>
                                Battle Registration <br />
                                Half Day of Workshops <br />
                                (select 2 sessions at check out)
                            </p>
                            <p>
                                The Get Down <br />
                                Merch Pack (T-shirt and socks) $40
                            </p>
                        </div>

                        <div className={`wrapper ${active === 3 ? 'active' : ''}`} onClick={() => onClickHandler(3)}>
                            <h3>
                                Da Baby Package $31 <br />
                                Save 10%
                            </h3>
                            <h3>
                                (Individual = $35)
                            </h3>
                            <p>
                                Battle Registration <br />
                            </p>
                            <p>
                                The Get Down <br />
                                Merch Pack (T-shirt and socks) $40
                            </p>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    );
}

export default PackagePage;