// Externals
import React from 'react';

// google-maps-react
import GoogleMapReact from 'google-map-react';

// components
import Header from '../../components/header/header.component';
import MyButton from '../../components/myButton/myButton.component';
import Footer from '../../components/footer/footer.component';

// styles
import './contactUs.styles.scss';

const ContactUsPage = () => {

    return (
        <div>
            <Header />
            <div className="contactus-header">
                <h3>CONT<span className='yellow-top'>A</span>CT US</h3>
            </div>
            <div>
                <div className='contactus-body'>
                    <div className='map'>
                        <GoogleMapReact
                        style={{width: "100%", height: "600",}}
                            bootstrapURLKeys={{ key: 'AIzaSyBI9D2-029o7m9KsbD9pbS8uB4caznCaTc' }}
                            defaultCenter={{lat: -35.2820279,lng: 149.1286726}}
                            defaultZoom={15}
                        >    
                        </GoogleMapReact>
                    </div>

                    <div className='form-body'>
                        <form>
                            <h2>GET IN TOUCH</h2>
                            <input className='inputText' type="text" placeholder='Name' name="name" />
                            <input className='inputText' type="email" placeholder='Email' name="email" />
                            <textarea className='textarea' placeholder='Message' rows="6"/>
                            <MyButton />
                        </form>
                    </div>
                    
                    
                </div>
                <div></div>
            </div>
            <Footer />
        </div>
    )
}

//
//AIzaSyB3NfJkGO_gxydGbNfjklLhCVcXhtv3ErI
export default ContactUsPage;