// Externals
import React from 'react';

// components
import HeroImageCarousel from '../../components/heroImageCarousel/heroImageCarousel.component';
import SDFFestival from '../../components/sdfFestival/sdfFestival.component';
import TheFestival from '../../components/theFestival/theFestival.component';
import DanceBattle from '../../components/danceBattle/danceBattle.component';
import TheGetDown from '../../components/theGetDown/theGetDown.component';
import Workhops from '../../components/workshops/workshops.component';
import Footer from '../../components/footer/footer.component';

// styles
import './homepage.styles.scss';

const Homepage = () => {
    
    return (
        <div>
            <HeroImageCarousel />
            <SDFFestival />
            <TheFestival />
            <DanceBattle />
            <TheGetDown />
            <Workhops />
            <Footer /> 
        </div>
    );
}

export default Homepage;