// Externals
import React from 'react';

// styles
import './myTextInput.styles.scss';

const MyTextInput = ({ label, name }) => {

    return (
        <label className='label'>
            {label}
            <input type="text" className='text-input'/>
        </label>
    );
}

export default MyTextInput;