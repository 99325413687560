// Externals
import React from 'react';

// styles
import './myTextArea.styles.scss';

const MyTextArea = ({ label }) => {

    return (
        <label className='label'>
            {label}
            <textarea ></textarea>
        </label>
    );
}

export default MyTextArea;