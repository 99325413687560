// Externals
import React from 'react';

// Assets
import AssetImage from '../../assets/images/CSDF-623-min.jpg'

// styles
import './sdfFestival.styles.scss';

const SDFFestival = () => {

    return (
        <div className='SDFFestival'>
            <div className='SDFFestival-image' style={{ backgroundImage: `url(${AssetImage})` }}></div>
            <div className='SDFFestival-text'>
                <div className='title-wrapper'>
                    <div className='title'>
                        <h3>
                            CANBERRA
                            <br />
                            STREET DANCE FESTIVAL 2022
                            <br />
                            9th - 10th of April 
                        </h3>
                    </div>
                </div>
                <div className='paragraph-wrapper'>
                    <p className='paragraph'>Canberra Street Dance Festival (Canberra SDF), is a community festival which celebrates the four main elements of Hip Hop culture; dance, DJing, MCing, and graffiti. There is so much more to Hip Hop than dance crazes and we want to share its rich history and culture with the Canberra community.</p>
                    <p className='paragraph'>We are bringing together the street scene for a two day festival of performance, stalls, dance battles, workshops and parties to celebrate Hip Hop and street culture.</p>
                </div>
            </div>
        </div>
    );
}

export default SDFFestival;