// Externals
import React from 'react';

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// Assets
import AssetImage from '../../assets/images/CSDF-12-min.jpg';
import AssetImage1 from '../../assets/images/CSDF-298-min.jpg';

// Styles
import './workshops.styles.scss';

const Workhops = () => {

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1   
    };

    return (
        <div className='workshop-wrapper'>
            <Slider {...settings} style={{ maxWidth: '100%', overflow: 'hidden' }}>  
                <div>
                    <div className='workshops' style={{ backgroundImage:`url(${AssetImage})` }}>
                        <div style={{ position: 'relative', width: '90%', maxWidth: '1140px', margin: '0 auto' }}>
                            <div className='workshops-text'>
                                <h3 className='title'>WORKSHO<span className='yellow-top'>PS</span></h3>
                                <h5 className='date'>Saturday the 10th of April</h5>
                                <p className='paragraph'>After getting inspired at the festival join us for a day of workshops in dance, MCing, DJing and music</p>
                                <p className='paragraph'>production. There will be both local and interstate teachers ready to share knowledge of their craft</p>
                                <p className='paragraph'>and the history and culture of Hip Hop.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='workshops' style={{ backgroundImage:`url(${AssetImage1})` }}>
                        <div style={{ position: 'relative', width: '90%', maxWidth: '1140px', margin: '0 auto' }}>
                            <div className='workshops-text'>
                                <h3 className='title'>WORKSHO<span className='yellow-top'>PS</span></h3>
                                <h5 className='date'>Saturday the 10th of April</h5>
                                <p className='paragraph'>After getting inspired at the festival join us for a day of workshops in dance, MCing, DJing and music</p>
                                <p className='paragraph'>production. There will be both local and interstate teachers ready to share knowledge of their craft</p>
                                <p className='paragraph'>and the history and culture of Hip Hop.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );

}

export default Workhops;